<template>
  <pcis-steps
    :form-type="formType"
    @prev-click="onPrevClick"
    @next-click="onNextClick"
  >
    <el-card>
      <Terms
        ref="terms"
        :terms="{
          data: $tm('personal.agreement.contents'),
          title: 'title',
          detail: 'detail',
        }"
        :agree-text="$t('personal.agreement.agree')"
        v-model="agreement"
      />
    </el-card>
  </pcis-steps>
</template>

<style scoped>
.step-agreement {
  padding: 20px 0px;
}

:deep(.detail a span),:deep(.agree a span) {
  font-size: 16px;
}

</style>

<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";
import Terms from "@/components/Terms.vue";
import * as message from "@/plugins/message";

export default {
  extends: BaseStep,
  name: "PersonalAgreement",
  components: { Terms },
  data() {
    return {
    }
  },
  methods: {
    onPrevClick(event) {
      event.goPrev();
    },
    onNextClick(event) {
      if (!this.agreement) {
        message.WarningAlert(this.t("alert"));
        return;
      }
      event.goNext();
    },
    resetAuthen() {
      this.$store.commit("resetAuthen");
    }
  },
  created() {
    this.resetAuthen()
  },
  beforeRouteUpdate() {
    this.resetAuthen()
  },
  computed: {
    agreement: {
      get: function () {
        return this.$store.state.personal.agreement;
      },
      set: function (val) {
        this.$store.commit("updateAgreement", val);
      },
    },
  }
};
</script>
